import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import OrderDetail from '../../entities/sales/orderdetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import Order from '@/store/entities/sales/order';

interface OrderDetailState extends ListState<OrderDetail>{
    editModel:OrderDetail;    
    order: number;
    selectIndex: number;
}
class OrderDetailModule extends ListModule<OrderDetailState,any,OrderDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<OrderDetail>(),
        loading:false,
        editModel: new OrderDetail(),
        activeList: new Array<OrderDetail>(),
        order: 0,
        selectIndex: null,
        path: 'order-details'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<OrderDetailState, any>, payload: any) {
            context.state.loading = true;
            if (payload.data.order_id == null) payload.data.order_id = context.state.order;
            let response = await Ajax.post('/api/order-details', payload.data).catch(e => {
                context.state.loading = false;
                return null;
            });
            context.state.loading = false;
            if (response == null)
                return response;

            return response.data.result;
        },
        async update(context: ActionContext<OrderDetailState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/order-details/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return null;
            });
            context.state.loading = false;
            if (response == null)
                return response;

            return response.data.result;

        },
        async delete(context: ActionContext<OrderDetailState, any>, payload: any) {
            context.state.loading = true;
            let id = payload.data.id;
            let response = await Ajax.delete('/api/order-details/' + id).catch(e => {
                context.state.loading = false;
                return null;
            });

            if (response == null)
                return response;

            context.state.loading = false;
            let index = 0;
            context.state.list.forEach((detail: OrderDetail, idx) => {
                if (detail.id == payload.data.id)
                    index = idx;
            });
            context.state.list.splice(index, 1);
            context.state.totalCount = context.state.list.length;

            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        init(state: OrderDetailState, model: Order) {
            state.editModel = Object.assign({});
            state.order = model.id;
            state.totalCount = 0;
            state.selectIndex = null;

            if (model != undefined) {
                state.order = model.id;
                state.list = model.details;
                state.totalCount = state.list.length;

                state.list.forEach(a => {
                    let state = Util.abp.list.getItem(a.current_state_id);
                    if (state == null) {
                        a.state_color = state.extra
                    }
                });
            }
        },
        edit(state: OrderDetailState, model: OrderDetail) {
            state.editModel = model;
            state.editModel.quantity = parseInt(String(state.editModel.quantity));           
        },
        add(state: OrderDetailState, product: OrderDetail) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id == product.product_id;
            });

            if (exist.length > 0) {
                exist[0].quantity = parseInt(String(product.quantity));
                exist[0].current_state_id = product.current_state_id;
                exist[0].discounts = product.discounts;
                exist[0].discount_type = product.discount_type;
                exist[0].price = product.price;
                exist[0].price_wt = product.price_wt;
                exist[0].total = product.total;
                exist[0].total_wt = product.total_wt;
                exist[0].taxes = product.total_wt;
                
            } else {
                state.list.push(product);
            }
            state.totalCount = state.list.length;
        },
        setSelectIndex(state: OrderDetailState, index: number) {
            state.selectIndex = index;
        },
        updateState(state: OrderDetailState, list: Array<any>) {
            state.list.forEach(a => {
                let item = list.filter(b => { return b.id == a.id })[0];
                if (item != undefined) {
                    a.current_state_id = item.current_state_id;
                }
            });
        },
        updateStocks(state: OrderDetailState, products: any) {
            state.list.forEach(item => {
                let exist = products.filter((product: any) => {
                    return item.product_id === product.id;
                });

                if (exist.length > 0) {
                    item.stock = exist[0].stock;
                }
            });
        },
    });
}
const orderdetailModule=new OrderDetailModule();
export default orderdetailModule;