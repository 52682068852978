import Entity from '../entity'

export default class ProductSchedule extends Entity<number> {
    product_id: number;
    schedule_id: number;
    price: number;
    price_wt: number;

    schedule: any;
}

