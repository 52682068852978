import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import IncomeOutput from '../../entities/cashiers/incomeoutput'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';
import IncomeOutputHistory from '@/store/entities/sales/history';

interface IncomeOutputState extends ListState<IncomeOutput>{
    editModel: IncomeOutput;
    hisLoading: boolean;
    history: Array<IncomeOutputHistory>;
    reportList: Array<any>;    
    trans_type: string;       
}
class IncomeOutputModule extends ListModule<IncomeOutputState,any,IncomeOutput>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<IncomeOutput>(),
        loading: false,
        hisLoading: false,
        editModel: new IncomeOutput(),
        activeList: new Array<IncomeOutput>(),
        history: new Array<IncomeOutputHistory>(),
        reportList: new Array<any>(),
        trans_type: 'I',
        path: 'income-outputs'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<IncomeOutputState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<IncomeOutput>;
            context.state.totalCount = page.total;
            context.state.list = page.data;
        },
        async getLastNumber(context: ActionContext<IncomeOutputState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/income-outputs/last-number', { params: payload.data }).then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<IncomeOutputState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/income-outputs/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async confirm(context: ActionContext<IncomeOutputState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/income-outputs/confirm', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async print(context: ActionContext<IncomeOutputState, any>, payload: any) {
            context.state.loading = true;
             
            let response = await Ajax.get('/api/income-output-invoice', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
        async reportInoutMoves(context: ActionContext<IncomeOutputState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/inout-moves', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result;
            context.state.loading = false;
        },
        async reportCostCenterMoves(context: ActionContext<IncomeOutputState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/cost-center-moves', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations 
        new(state: IncomeOutputState, model: IncomeOutput) {
            let date = new Date();

            state.editModel = Object.assign({});
            state.editModel.operation_date = Util.abp.clock.today();
            state.editModel.user_type_id = Util.abp.list.get('T_INOUT_USER', 'CL');
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.payment_method_id = Util.abp.setting.get('DEFAULT_PAYMENT_METHOD');
            state.editModel.conversion_rate = 1;
            state.editModel.amount = 0.00;
            state.editModel.income_output_concept_id = Util.abp.setting.get((state.trans_type == 'I' ? 'CONCEPT_IN_DEFAULT' : (state.trans_type == 'E' ? 'CONCEPT_OUT_DEFAULT' : 'CONCEPT_TRANS_IN_DEFAULT')));
            state.editModel.serie = Util.abp.setting.get((state.trans_type == 'I' ? 'INCOME_SERIE' : (state.trans_type == 'E' ? 'OUTPUT_SERIE' : 'INOUT_SERIE')));
                        
            state.editModel.number = '00000';
        },
        edit(state: IncomeOutputState, model: IncomeOutput) {
            state.editModel = model;            
        },
        setType(state: IncomeOutputState, item: any) {
            state.trans_type = item;
        }
    });
}
const incomeoutputModule=new IncomeOutputModule();
export default incomeoutputModule;