import Entity from '../entity'
import OrderDetail from './orderdetail';
import Customer from '../customers/customer';
import Currency from '../preferences/currency';
import Transaction from '../cashiers/transaction';

export default class Order extends Entity<number>{
    reference: string;
    number: number;
    company_id: number;
    branch_id: number;
    order_type_id: number;
    currency_id: number;
    current_state_id: number;
    customer_id: number;
    delivery_address_id: number;
    seller_id: number;
    payment_gateway_id: number;
    customer_card_id: number;
    tax_id: number;
    tax_rate: number;
    including_taxes: boolean;
    origin: string;
    exchange_rate: number;
    discount_type: string;
    subtotal: number;
    subtotal_wt: number;
    taxes: number;
    discounts: number;
    total: number;
    total_wt: number;
    total_paid: number;
    balance: number;
    order_date: string;
    close_date: string;
    comment: string;
    valid: boolean;
    invoiced: boolean;
    delivered: boolean;
    details: Array<OrderDetail>;
    payments: Array<Transaction>;

    currency_sign: string;
    customer: Customer;
    currency: Currency;
    delivery_address: any;
    
}
