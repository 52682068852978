import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Sale from '../../entities/sales/sale'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import appconst from '../../../lib/appconst'
import PageResult from '@/store/entities/page-result';
import SaleDetail from '@/store/entities/sales/saledetail';
import SaleHistory from '@/store/entities/sales/history';
import Address from '@/store/entities/customers/address';
import Currency from '@/store/entities/preferences/currency';
import axios from 'axios'
import Booking from '@/store/entities/bookings/booking';

interface SaleState extends ListState<Sale>{
    editModel:Sale;       
    hisLoading: boolean;
    hasChanges: boolean;        
    history: Array<SaleHistory>;   
    addresses: Array<Address>;
    reportList: Array<any>;
    monthly: Array<any>;
    filename: string;
    discounts: number;
}
class SaleModule extends ListModule<SaleState,any,Sale>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Sale>(),
        loading:false,
        hisLoading: false,
        hasChanges: false,
        editModel: new Sale(),
        activeList: new Array<Sale>(),
        addresses: new Array<Address>(),
        history: new Array<SaleHistory>(),
        reportList: new Array<any>(),
        monthly: new Array<any>(),
        filename: '',
        discounts: 0,
        path: 'sales'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<SaleState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Sale>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }

            let sState = Util.abp.list.getCodeList('E_SALE');
            context.state.list.forEach(item => {
                if (item.invoice_type.invoiceable && item.invoice_type.code == 'BV' && item.valid && !item.invoiced && item.current_state_id != sState.PR) {
                    item.current_state.name = 'Por Procesar / ' + item.current_state.name;
                    item.current_state.color = '#039BE5FF';
                } else if (item.invoice_type.invoiceable && item.invoice_type.code == 'F' && item.valid && item.invoiced) {
                    item.current_state.name = 'Aceptada / ' + item.current_state.name;
                } else if (!item.invoice_type.invoiceable && item.valid && item.current_state_id == sState.PE) {
                    item.current_state.name = 'Confirmado';
                    item.current_state.color = '#039BE5FF';
                }
            });

            return context.state.list;
        },
        async create(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async update(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async cancel(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/sales/cancel', { id: payload.data.id, cancel_reason: payload.data.cancel_reason } ).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
        },
        async invoice(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true; 
            let response = await Ajax.post('/api/sale-invoices', payload.data).catch(e => {
                context.state.loading = false;
                return false;
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            if (!response)
                return response;
            else if ((response as any).data != undefined) 
                return (response as any).data.result;
            return false;
        },
        async delivery(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/sales/delivery', payload.data).catch(e => {
                context.state.loading = false;
                return false;
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return true;
        },
        async getHistory(context: ActionContext<SaleState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/sales/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async print(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;

            let format = Util.abp.setting.get('SALES_PRINT_FORMAT');
            let localUrl = Util.abp.setting.get('LOCAL_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');
            
            let api = (format == 'A4' ? 'sale-invoice' :'sale-invoice-receipt');

            let response = await Ajax.get('/api/' + api, { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            if (mode == 'LOCAL' && useRemote == 1 && format == 'TK') {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;                
                if (method == 'POST') {
                    await axios.post(localUrl, response.data.result);
                } else {
                    let href: any = localUrl + "?endpoint=" + (url + 'api/' + api) + "&token=" + window.abp.auth.getToken() + "&id=" + payload.id;
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    return '';
                    //await axios.get(localUrl, { params: response.data.result });
                }
                return '';
            } else 
                return response.data.result;            
        },
        async getPayableDocuments(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/sales/payable-documents', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Sale>;
            context.state.totalCount = page.total;
            context.state.list = page.data;
        },
        async getSalesForCreditNotes(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/sales/for-credit-notes', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Sale>;
            context.state.totalCount = page.total;
            context.state.list = page.data;
        },
        async downloadInvoiceFile(context: ActionContext<SaleState, any>, payload: any) {
            let enableNube = Util.abp.setting.get('NUBEFACT_EINVOICE_ENABLE');
            let enableSunat = Util.abp.setting.get('SUNAT_EINVOICE_ENABLE');
            context.state.loading = true;
            payload.data.sale = payload.data.id;
            let data = { params: payload.data };
            if (enableSunat == 1) data['responseType'] = 'blob';
            let reponse = await Ajax.get('/api/sale-invoices-download', data).then(response => {
                const link = document.createElement('a');
                if (enableNube == 1) {
                    const url = response.data.result;
                    link.href = url;
                    if (payload.data.type == 'pdf')
                        link.target = '_blank';
                } else if (enableSunat == 1) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    link.href = url;
                }
                link.setAttribute('download', payload.data.filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async getMonthlySales(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.monthly = [];
            let reponse = await Ajax.get('/api/sales/monthly', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.monthly = reponse.data.result;
            context.state.loading = false;
        },

        async reportSalesSellers(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-sellers', {params: payload.data}).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e); 
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSalesQuery(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-query', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSalesProducts(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-products', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSalesDiscounts(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-discounts', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSalesCustomers(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-customers', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSalesCanceled(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sales-canceled', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSunatFormat14(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/sunat-format14', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportTopProducts(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/top-products', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportTopCustomers(context: ActionContext<SaleState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/top-customers', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: SaleState, model: Sale) { 
            state.addresses = [];

            let currency = new Currency();
            currency.id = Util.abp.setting.get('DEFAULT_CURRENCY');
            currency.sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            currency.conversion_rate = 1;
            state.editModel = Object.assign({});

            state.editModel.customer = Object.assign({});
            state.editModel.customer_id = null;

            state.editModel.delivery_address = null;
            state.editModel.delivery_address_id = null;
            state.editModel.sale_date = Util.abp.clock.today();
            state.editModel.expiration_date = Util.abp.clock.today();
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.tax_id = Util.abp.setting.get('IGV_TAXES');
            state.editModel.tax_rate = Util.abp.setting.get('TAX_RATE');
            state.editModel.currency = currency;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_SALES_DOCUMENT');
            state.editModel.invoice_type_name = 'Comprobante';
            state.editModel.invoice_type = { id: state.editModel.invoice_type_id, name: 'Comprobante'};
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.payment_type_id = Util.abp.list.get('C_SALEPAID', 'CO').id;
            state.editModel.exchange_rate = 1;
            state.editModel.discount_type = 'amount';
            state.editModel.discounts = 0.00;
            state.editModel.subtotal = 0.00;
            state.editModel.subtotal_wt = 0.00;
            state.editModel.tax_igv = 0.00;
            state.editModel.tax_isc = 0.00;
            state.editModel.tax_others = 0.00;
            state.editModel.taxes = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00;
            state.editModel.invoice_number = '0';
            state.editModel.invoiced = false;
            state.editModel.invoice_serie = '';
            state.editModel.details = new Array<SaleDetail>();
            state.hasChanges = false;
            state.discounts = 0;

            state.editModel.seller_id = Util.abp.session.userId;
        },
        edit(state: SaleState, model: Sale) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.customer = model.customer;
            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.invoice_type_name = model.invoice_type.description;

            state.hasChanges = false;
            state.discounts = model.discounts;
        },
        setReportList(state: SaleState, list: Array<any>) {
            state.reportList = list;
            state.totalCount = list.length;
        },
        setCurrency(state: SaleState, currency: any) {
            state.editModel.currency = currency;
            state.editModel.currency_id = currency.id;
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
            state.hasChanges = true;
        },
        setCustomer(state: SaleState, customer: any) {
            state.editModel.customer = customer;
            state.editModel.customer_id = customer.id;
            state.hasChanges = true;
        },
        updatePayments(state: SaleState, total_paid: any) {
            let sState = Util.abp.list.getCodeList('E_SALE');
            state.editModel.total_paid = parseFloat(total_paid);
            state.editModel.balance = parseFloat((state.editModel.total_wt - state.editModel.total_paid).toFixed(2));
            if (state.editModel.total_paid > 0)
                state.editModel.current_state_id = sState.PP;

            if (state.editModel.total_paid == state.editModel.total_wt)
                state.editModel.current_state_id = sState.PA;
            state.hasChanges = true;
        },
        setAddresses(state: SaleState, addresses: any) {
            state.addresses = addresses;
        },
        setInvoice(state: SaleState, data: any) {
            state.editModel.invoice_type_name = data.invoice_type.description;
            state.editModel.invoice_type_id = data.invoice_type.id;
            if (data.invoice_type.series[0] != undefined)
                state.editModel.invoice_serie = data.invoice_type.series[0].serie;
            state.editModel.invoice_number = data.number;
            state.hasChanges = true;
        },
        setTotals(state: SaleState, data: any) {
            if (state.editModel.total_wt != data.total_wt) state.hasChanges = true;
            state.editModel.subtotal = data.subtotal;
            state.editModel.subtotal_wt = data.subtotal_wt;
            state.editModel.discounts = data.discount_total;
            state.editModel.taxes = data.taxes;
            state.editModel.total = data.total;
            state.editModel.total_wt = data.total_wt;
            state.editModel.total_paid = data.total_paid;
            state.editModel.total_return = data.total_return;
            state.editModel.balance = state.editModel.total_wt - (state.editModel.total_paid + state.editModel.total_return);
            state.discounts = data.discount_total;
            if (Math.abs(state.editModel.balance) < 0.01) state.editModel.balance = 0;
        },
        loadFromBooking(state: SaleState, model: Booking) {
            if (model == undefined) return;
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            state.addresses = [];

            state.editModel = Object.assign({});
            state.editModel.id = null;
            state.editModel.customer = model.customer;
            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.reference = model.reference;
            state.editModel.booking_id = model.id;
            state.editModel.booking_nro = model.nro;
            state.editModel.sale_date = Util.abp.clock.today();
            state.editModel.expiration_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.payment_type_id = Util.abp.list.get('C_SALEPAID', 'CO').id;
            state.editModel.customer_id = model.customer_id;
            state.editModel.currency = model.currency;
            state.editModel.currency_id = model.currency_id;
            state.editModel.seller_id = Util.abp.session.userId;
            state.editModel.including_taxes = true;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_SALES_DOCUMENT');
            state.editModel.invoice_type_name = 'Comprobante';
            state.editModel.invoice_serie = '';
            state.editModel.invoice_number = '0';
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.discount_type = model.discount_type;
            state.editModel.discounts = model.discounts;
            state.editModel.subtotal = model.total;
            state.editModel.subtotal_wt = model.total_wt;
            state.editModel.tax_igv = model.taxes;
            state.editModel.tax_isc = 0.00;
            state.editModel.tax_others = 0.00;
            state.editModel.taxes = model.taxes;
            state.editModel.total = model.total;
            state.editModel.total_wt = model.total_wt;
            state.editModel.total_paid = model.total_paid;
            state.editModel.balance = model.total_wt - model.total_paid;
            state.editModel.details = new Array<SaleDetail>();
            state.hasChanges = false;
            if (model['payments'] != undefined)
                state.editModel.payments = model['payments'];

            model.products.forEach((item, index) => {
                let det = new SaleDetail();
                det = Util.extend(true, {}, item);
                det.warehouse_id = Util.abp.session.warehouseId;
                det.unity_id = Util.abp.setting.get('DEFAULT_UNITY');
                det.tax_id = model.tax_id;
                det.tax_rate = model.tax_rate;
                det.stock_physical = 0;
                det.stock_reserved = 0;
                det.stock = 0;
                det.discount_type = 'percent';
                det.discounts = 0;
                det.quantity = item.duration;
                det.total_wt = Util.convertAmountToCurrency(item.total_wt, item.currency_id, model.currency_id)
                det.total = Util.removeTaxes(det.total_wt, det.tax_rate);
                det.price_wt = det.total_wt / det.quantity;
                det.price = det.total / det.quantity;
                det.taxes = det.total_wt - det.total;
                det.service = true;
                det.gift = false;

                Util.updateTotalDetails(det);
                det.taxes = parseFloat((det.total_wt - det.total).toFixed(6));
                
                delete det.id;
                state.editModel.details.push(det);
            });
        },
        setChanged(state: SaleState, value: boolean) {
            state.hasChanges = value;
        },        
    });
}
const saleModule=new SaleModule();
export default saleModule;