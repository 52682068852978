import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Order from '../../entities/sales/order'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import appconst from '../../../lib/appconst'
import PageResult from '@/store/entities/page-result';
import OrderDetail from '@/store/entities/sales/orderdetail';
import OrderHistory from '@/store/entities/sales/history';
import Currency from '@/store/entities/preferences/currency';
import axios from 'axios'
import Transaction from '@/store/entities/cashiers/transaction';

interface OrderState extends ListState<Order>{
    editModel:Order;       
    hisLoading: boolean;
    loadingUpdate: boolean;
    hasTotalChanges: boolean;
    hasChanges: boolean;
    availableTables: Array<any>;   
    payments: Array<any>;   
    pending: Array<any>;   
    showPaymentForm: boolean;
    showCartForm: boolean;
    loaded: boolean;
    receipt: string;
    current_page: string;
    history: Array<OrderHistory>; 
}
class OrderModule extends ListModule<OrderState,any,Order>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Order>(),
        loading:false,
        hisLoading: false,
        loadingUpdate: false,
        editModel: new Order(),
        activeList: new Array<Order>(),
        history: new Array<OrderHistory>(),
        hasTotalChanges: false,
        hasChanges: false,
        availableTables: new Array<any>(),
        payments: new Array<any>(),
        pending: new Array<any>(),
        showPaymentForm: false,
        showCartForm: false,
        loaded: false,
        receipt: '',
        current_page: 'list',
        path: 'orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<OrderState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Order>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }

            context.state.list.forEach(item => {
                item['products'] = item.details.length;
                item['products_stock'] = item.details.filter(a => {
                    return a.stock > 0;
                }).length;

                if (item['products_stock'] != item['products']) {
                    item['color_stock'] = '#FF5252';
                }
            });

            return context.state.list;
        },
        async getOpenOrders(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/orders-opened', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = response.data.result.length;
            context.state.list = response.data.result;

            return context.state.list;
        },
        async getPendingOrders(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/orders-opened', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = response.data.result.length;
            context.state.pending = response.data.result;

            return context.state.pending;
        },
        async get(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loading = true;
            let response: any = await Ajax.get('/api/orders/get/' + payload.data.id).catch(e => {
                context.state.loading = false;
                return false;
            });
            if (response === false) return response;

            context.state.loading = false;
            if (response.data.result.order != undefined) {
                context.state.editModel = response.data.result.order;
                context.state.availableTables = response.data.result.availableTables;
                context.state.payments = response.data.result.payments;
                context.state.receipt = response.data.result.receipt;
                context.state.loaded = true;

                return response.data.result;
            }
            return null;
        },
        async create(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasTotalChanges = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async update(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/orders/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasTotalChanges = false;
            context.state.hasChanges = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async attend(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loadingUpdate = true;
            let response = await Ajax.post('/api/orders/attend', payload.data).catch(e => {
                context.state.loadingUpdate = false;
                return Promise.reject(e);
            });
            context.state.loadingUpdate = false;
            if (response.data.result != undefined) {
                let data = response.data.result;
                context.state.editModel.current_state_id = data.current_state_id;
                context.state.hasTotalChanges = false;
                context.state.hasChanges = false;
                return data;
            }
            return null;
        },
        async serve(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loadingUpdate = true;
            let response = await Ajax.post('/api/orders/serve', payload.data).catch(e => {
                context.state.loadingUpdate = false;
                return Promise.reject(e);
            });
            context.state.loadingUpdate = false;
            if (response.data.result != undefined) {
                context.state.editModel.current_state_id = response.data.result.current_state_id;
                context.state.hasTotalChanges = false;
                context.state.hasChanges = false;
                return response.data.result;
            }
            return null;
        },
        async delivery(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loadingUpdate = true;
            let response = await Ajax.post('/api/orders/delivery', payload.data).catch(e => {
                context.state.loadingUpdate = false;
                return Promise.reject(e);
            });
            context.state.loadingUpdate = false;
            if (response.data.result != undefined) {
                context.state.editModel.current_state_id = response.data.result.current_state_id;
                context.state.hasTotalChanges = false;
                context.state.hasChanges = false;
                return response.data.result;
            }
            return null;
        },
        async payment(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loadingUpdate = true;
            let response = await Ajax.post('/api/orders/payment', payload.data).catch(e => {
                context.state.loadingUpdate = false;
                return Promise.reject(e);
            });
            context.state.loadingUpdate = false;
            if (response.data.result != undefined) {
                context.state.editModel.current_state_id = response.data.result.current_state_id;
                context.state.hasTotalChanges = false;
                context.state.hasChanges = false;
                context.state.receipt = response.data.result.receipt;
                return response.data.result;
            }
            return null;
        },
        async close(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loadingUpdate = true;
            let response = await Ajax.post('/api/orders/close', payload.data).catch(e => {
                context.state.loadingUpdate = false;
                return Promise.reject(e);
            });
            context.state.loadingUpdate = false;
            return response.data.result;
        },
        async updateTotals(context: ActionContext<OrderState, any>, payload: any) {
            if (context.state.loadingUpdate || context.state.loading) return;
            let response = await Ajax.put('/api/orders/totals/' + payload.data.id).catch(e => {
                return Promise.reject(e);
            });
            context.state.hasTotalChanges = false;
            return response.data.result;
        },
        async getHistory(context: ActionContext<OrderState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/orders/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async printCommand(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loadingUpdate = true;

            let localUrl = Util.abp.setting.get('LOCAL_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');

            let response = await Ajax.get('/api/order-print-command', { params: { id: payload.data.id } }).catch(e => {
                context.state.loadingUpdate = false;
                return Promise.reject(e);
            });
            context.state.loadingUpdate = false;

            if (mode == 'LOCAL' && useRemote == 1) {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;
                response.data.result.forEach(async (data) => {
                    if (method == 'POST') {
                        await axios.post(localUrl, data);
                    } else {
                        await axios.get(localUrl, { params: data });
                    }
                })                
                return '';
            } else {
                return response.data.result;
            }
        },
        async printReceipt(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loadingUpdate = true;

            let localUrl = Util.abp.setting.get('LOCAL_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');

            let response = await Ajax.get('/api/order-print-receipt', { params: { id: payload.data.id } }).catch(e => {
                context.state.loadingUpdate = false;
                return Promise.reject(e);
            });
            context.state.loadingUpdate = false;

            if (mode == 'LOCAL' && useRemote == 1) {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;
                if (method == 'POST') {
                    await axios.post(localUrl, response.data.result);
                } else {
                    let href: any = localUrl + "?endpoint=" + (url + 'api/order-print-receipt') + "&token=" + window.abp.auth.getToken() + "&id=" + payload.data.id;
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    return '';
                    //await axios.get(localUrl, { params: response.data.result });
                }
                return '';
            } else {
                return response.data.result;
            }
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: OrderState, model: Order) { 
            let currency = new Currency();
            currency.id = Util.abp.setting.get('DEFAULT_CURRENCY');
            currency.sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            currency.conversion_rate = 1;
            state.editModel = Object.assign({});

            state.editModel.customer = Object.assign({});
            state.editModel.customer_id = null;  
            state.editModel.delivery_address_id = null;
            state.editModel.order_date = Util.abp.clock.today();
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.order_type_id = null;
            state.editModel.tax_id = Util.abp.setting.get('IGV_TAXES');
            state.editModel.tax_rate = Util.abp.setting.get('TAX_RATE');
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.currency = currency;
            state.editModel.exchange_rate = 1;
            state.editModel.discounts = 0.00;
            state.editModel.subtotal = 0.00;
            state.editModel.subtotal_wt = 0.00;
            state.editModel.taxes = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00;
            state.editModel.invoiced = false;
            state.editModel.including_taxes = true;
            state.editModel.origin = 'local';
            state.editModel.details = new Array<OrderDetail>();
            state.hasTotalChanges = false;
            state.hasChanges = false;

            state.editModel.seller_id = Util.abp.session.userId;
        },
        edit(state: OrderState, model: Order) {
            state.editModel = model;
            state.editModel.customer = model.customer;
            if (model.currency != null) {
                state.editModel.currency_sign = model.currency.sign;
            }
            state.hasTotalChanges = false;
            state.hasChanges = false;
        },
        setTotals(state: OrderState, data: any) {
            state.editModel.subtotal = data.subtotal;
            state.editModel.subtotal_wt = data.subtotal_wt;
            state.editModel.discounts = data.discounts;
            state.editModel.taxes = data.taxes;
            state.editModel.total = data.total;
            state.editModel.total_wt = data.total_wt;
            state.editModel.total_paid = data.total_paid;
            state.editModel.balance = state.editModel.total_wt - state.editModel.total_paid;
            if (Math.abs(state.editModel.balance) < 0.01) state.editModel.balance = 0;
        },
        setData(state: OrderState, data: Order) {
            if (data.current_state_id != undefined)
                state.editModel.current_state_id = data.current_state_id;
        },        
        setChanged(state: OrderState, value: boolean) {
            state.hasChanges = value;
        },
        setTotalChanged(state: OrderState, value: boolean) {
            state.hasTotalChanges = value;
        },
        togglePaymentForm(state: OrderState, value: boolean) {
            state.showPaymentForm = !state.showPaymentForm;
            if (value != undefined)
                state.showPaymentForm = value;
        },
        toggleCartForm(state: OrderState, value: boolean) {
            state.showCartForm = !state.showCartForm;
            if (value != undefined)
                state.showCartForm = value;
        },
        setCurrentPage(state: OrderState, page: string) {
            state.current_page = page;
        },
        addPayment(state: OrderState, item: any) {
            let payment = new Transaction();

            payment['order'] = state.editModel.id;            
            payment.type = 'I';
            payment.reference = state.editModel.reference;
            payment.currency_id = state.editModel.currency_id;
            payment.currency_sign = state.editModel.currency_sign;
            payment.state_id = Util.abp.list.get('E_PAID', 'V').id;
            payment.payment_method_id = item.payment_method.id;
            payment.payment = item.payment_method.name;
            payment.amount = item.amount;
            payment['due'] = item.due;
            payment.amount_paid = item.amount;
            payment.change = payment.amount_paid - payment['due'];
            if (payment.change < 0) payment.change = 0;

            state.payments.push(payment);
        },
        deletePayment(state: OrderState, index: any) {
            state.payments.splice(index, 1);
        },
        setLoaded(state: OrderState, value: boolean) {
            state.loaded = value;
        },



        //setCustomer(state: OrderState, customer: any) {
        //    state.editModel.customer = customer;
        //    state.editModel.customer_id = customer.id;
        //    state.hasTotalChanges = false;
        //    state.hasChanges = true;
        //},    
    });
}
const orderModule=new OrderModule();
export default orderModule;