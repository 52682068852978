import Entity from '../entity'

export default class Category extends Entity<number>{
    name: string;
    code: number;
    description: string;
    active: boolean;

    image_url: string;
    imageData: string;
}


