import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import CreditNoteDetail from '../../entities/sales/creditnotedetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import CreditNote from '@/store/entities/sales/creditnote';

interface CreditNoteDetailState extends ListState<CreditNoteDetail>{
    editModel:CreditNoteDetail;    
    creditnote:number;    
}
class CreditNoteDetailModule extends ListModule<CreditNoteDetailState,any,CreditNoteDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<CreditNoteDetail>(),
        loading:false,
        editModel: new CreditNoteDetail(),
        activeList: new Array<CreditNoteDetail>(),
        creditnote: 0,
        path: 'credit-notes'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<CreditNoteDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: CreditNoteDetailState, model: CreditNoteDetail) {
            state.editModel = model;
        },
        init(state: CreditNoteDetailState, model: CreditNote) {
            state.editModel = Object.assign({});
            if (model != undefined) {
                state.creditnote = model.id;
                state.list = model.details;
            }
        },
        setProduct(state: CreditNoteDetailState, product: any) {
            state.editModel.product = product;
            state.editModel.product.manufacturer_name = product.manufacturer.name;
            state.editModel.product.category_name = product.category_default != undefined ? product.category_default.name : '';

        },
        add(state: CreditNoteDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id;
            });

            if (exist.length > 0) {
                exist[0].quantity++;
                exist[0].total = exist[0].quantity * exist[0].price;
                exist[0].total_wt = exist[0].quantity * exist[0].price_wt
                exist[0].taxes = parseFloat((exist[0].total_wt - exist[0].total).toFixed(3));
            } else {
                let detail = new CreditNoteDetail();
                detail.credit_note_id = state.editModel.id;
                detail.product_id = product.id;
                detail.warehouse_id = (product['warehouse_id'] != undefined ? product['warehouse_id'] : Util.abp.session.warehouseId);
                detail.unity_id = product.unity_id;
                detail.product_name = product.name;
                detail.quantity = 1;
                detail.price = product.price;
                detail.price_wt = product.price_wt;
                detail.total = detail.quantity * detail.price;
                detail.total_wt = detail.quantity * detail.price_wt
                detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(3));
                detail.discounts = 0;                
                detail.service = product['service'];
                
                state.list.push(detail);
            }
        },
        delete(state: CreditNoteDetailState, index: number) {
            state.list.splice(index, 1);
        },
        save(state: CreditNoteDetailState, detail: CreditNoteDetail) {
            for (var i = 0; i < state.list.length; i++) {
                if (state.list[i].id == detail.id) {
                    state.list[i] = detail;
                }
            }
        },
    });
}
const creditnotedetailModule=new CreditNoteDetailModule();
export default creditnotedetailModule;