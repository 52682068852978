import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Service from '../../entities/catalogs/product'
import ServiceSchedule from '../../entities/catalogs/productschedule'
import PageResult from '@/store/entities/page-result';
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ServiceState extends ListState<Service>{
    editModel:Service;
    images: Array<any>;
    prices: Array<ServiceSchedule>;
    uploading: boolean;
    reportList: Array<any>;
    filename: string;
    activeList: Array<Service>;
}
class ServiceModule extends ListModule<ServiceState,any,Service>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Service>(),
        loading: false,
        uploading: false,
        editModel:new Service(),
        activeList: new Array<Service>(),
        reportList: new Array<any>(),
        filename: '',
        images: new Array<any>(),
        prices: new Array<ServiceSchedule>(),
        path: 'products',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ServiceState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            payload.data.has_stock = 0;
            let reponse = await Ajax.get('/api/products', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Service>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }
            context.state.list.forEach(a => {
                if (a.images != null && a.images.length > 0) {
                    a.url = a.images[0].url;
                    a.url_icon = (a.images[0].url_icon != null ? a.images[0].url_icon : a.images[0].url);
                    a.url_catalog = (a.images[0].url_catalog != null ? a.images[0].url_catalog : a.images[0].url);
                    a.url_box = (a.images[0].url_box != null ? a.images[0].url_box : a.images[0].url);
                    a.url_preview = (a.images[0].url_preview != null ? a.images[0].url_preview : a.images[0].url);
                }
            })

            return context.state.list;
        },
        async getAllActive(context: ActionContext<ServiceState, any>, payload: any) {
            if (context.state.activeList != undefined && context.state.activeList.length > 0 || context.state.loading) return true;
            let params = (payload.data == undefined ? {} : payload.data);
            params.perPage = -1;
            params.has_stock = 0;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.activeList = reponse.data.result;
            context.state.activeList.forEach(a => {
                a.product_type_name = Util.abp.list.getProductName(a.product_type_id);
            })
            context.state.loaded = true;
            context.state.loading = false;
        },
        async create(context: ActionContext<ServiceState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let item = Util.extend(true, {}, response.data.result); 
            if (response.data.result && context.state.activeList != undefined) {
                item.service_name = item.name;
                item.name = item.code + ' - ' + item.name
                context.state.activeList.push(item);
            }
            return response.data.result;
        },
        async getInfo(context: ActionContext<ServiceState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/products/get-info/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async uploadImage(context: ActionContext<ServiceState, any>, payload: any) {
            context.state.uploading = true;
            let response = await Ajax.post('/api/products/upload-image', payload.data).catch(e => {
                context.state.uploading = false;
                return Promise.reject(e);
            });
            context.state.uploading = false;
            context.state.images = response.data.result;
        },
        async deleteImage(context: ActionContext<ServiceState, any>, payload: any) {
            context.state.uploading = true;
            let response = await Ajax.delete('/api/products/delete-image/' + payload.data.id).catch(e => {
                context.state.uploading = false;
                return Promise.reject(e);
            });
            context.state.uploading = false;
            context.state.images = context.state.images.filter((item: any) => {
                return item.id !== payload.data.id;
            });
        },
        async getLastCode(context: ActionContext<ServiceState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/products/last-code', { params: payload.data}).then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ServiceState, model: Service) {
            state.editModel = Object.assign({});
            if (model != null && model != undefined)
                state.editModel = model;

            state.prices = [];
            state.images = [];
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.category_id = Util.abp.setting.get('DEFAULT_CATEGORY');
            state.editModel.available_id = Util.abp.list.get('T_AVAIL', 'B').id;            
            state.editModel.manufacturer_id = null;
            state.editModel.unity_id = Util.abp.setting.get('DEFAULT_UNITY');
            state.editModel.tax_id = Util.abp.setting.get('IGV_TAXES');
            state.editModel.price = 0;
            state.editModel.price_wt = 0;
            state.editModel.cost = 0;
            state.editModel.profit = Util.abp.setting.get('PRODUCT_PROFIT');
            state.editModel.code = '';
            state.editModel.product_type_id = Util.abp.list.getProdId('CA');
        },
        edit(state: ServiceState, model: Service) {
            state.images = [];
            state.editModel = model;
        },
        setImagesList(state: ServiceState, images: Array<any>) {
            state.images = images;
        },
        setPrices(state: ServiceState, data: any) {
            state.prices = [];
            data.schedules.forEach(a => {
                let price = data.prices.filter(p => { return p.schedule_id == a.id })[0];

                let item = new ServiceSchedule();
                item.product_id = state.editModel.id;
                item.schedule_id = a.id;
                item.price = (price != null ? price.price : state.editModel.price);
                item.price_wt = (price != null ? price.price_wt : state.editModel.price_wt);

                item.schedule = a;

                state.prices.push(item);
            });            
        },
    });
}
const serviceModule=new ServiceModule();
export default serviceModule;