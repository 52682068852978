import Entity from '../entity'

export default class Product extends Entity<number>{
    manufacturer_id: number;
    category_id: number;
    tax_id: number;
    product_type_id: number;
    available_id: number;
    currency_id: number;
    unity_id: number;
    name: string;
    description: string;
    code: string;
    reference: string;
    profit: number;
    price: number;
    price_wt: number;
    cost: number;
    active: boolean;
    is_pack: boolean;
    has_stock: boolean;
    last_purchase: string;
    tax: any;
    quantity: number;
    minimal_quantity: number;

    images: Array<any>;
    stock_physical: number;
    stock_reserved: number;
    stock: number;
    category_name: string;
    manufacturer_name: string;
    product_type_name: string;
    unity_name: string;
    unity: any;
    cost_wt: number;
    product_cost: number;
    tax_rate: number;
    unity_symbol: string;
    currency_sign: string;

    url: string;
    url_icon: string;
    url_catalog: string;
    url_box: string;
    url_preview: string;

}


