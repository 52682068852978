import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Manufacturer from '../../entities/catalogs/manufacturer'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ManufacturerState extends ListState<Manufacturer>{
    editModel: Manufacturer;
    activeList: Array<Manufacturer>;
    type: any;
    path: string;
}
class ManufacturerModule extends ListModule<ManufacturerState, any, Manufacturer>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Manufacturer>(),
        loading:false,
        editModel:new Manufacturer(),
        activeList: new Array<Manufacturer>(),
        type: null,
        path: 'manufacturers'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ManufacturerState, type: any) {
            state.type = type;
            state.editModel = Object.assign({});
        },
    });
}
const manufacturerModule=new ManufacturerModule();
export default manufacturerModule;