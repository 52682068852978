import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import app from './modules/app'
import session from './modules/session'
import user from './modules/admin/user'
import role from './modules/admin/role'
import tab from './modules/admin/tab'
import application from './modules/admin/application'
import report from './modules/admin/report'
import company from './modules/admin/company'
import branch from './modules/admin/branch' 
import dashboard from './modules/admin/dashboard'
import backup from './modules/admin/backup'
  
import customer from './modules/customers/customer'
import address from './modules/customers/address'
import documenttype from './modules/customers/documenttype'
import group from './modules/customers/group'

import booking from './modules/bookings/booking'
import bookingproduct from './modules/bookings/bookingproduct'

 
import category from './modules/catalogs/category'
import manufacturer from './modules/catalogs/manufacturer'
import measureunit from './modules/catalogs/measureunit'
import service from './modules/catalogs/service' 
import product from './modules/catalogs/product' 
import producttype from './modules/catalogs/producttype'
import productsearch from './modules/catalogs/productsearch'
import schedule from './modules/catalogs/schedule'
import productschedule from './modules/catalogs/productschedule'
import kit from './modules/catalogs/kit'
import pack from './modules/catalogs/pack'

import supplier from './modules/purchases/supplier'
import purchase from './modules/purchases/purchase'
import purchasedetail from './modules/purchases/purchasedetail'

import country from './modules/preferences/country'
import department from './modules/preferences/department'
import province from './modules/preferences/province'
import district from './modules/preferences/district'
import currency from './modules/preferences/currency'
import exchangerate from './modules/preferences/exchangerate'
import tax from './modules/preferences/tax'
import generaltype from './modules/preferences/generaltype'
import generaltypevalue from './modules/preferences/generaltypevalue'
import configuration from './modules/preferences/configuration'
import notification from './modules/preferences/notification'
import printer from './modules/preferences/printer'
import imagetype from './modules/preferences/imagetype'


import warehouse from './modules/stocks/warehouse'
import stock from './modules/stocks/stock'
import stockavailable from './modules/stocks/stockavailable'
import stockmvt from './modules/stocks/stockmvt'
import stockmvtreason from './modules/stocks/stockmvtreason'


import cashier from './modules/cashiers/cashier'
import cashierhistory from './modules/cashiers/cashierhistory'
import dailyclosure from './modules/cashiers/dailyclosure'
import paymentmethod from './modules/cashiers/paymentmethod'
import paymentgateway from './modules/cashiers/paymentgateway'
import incomeoutputconcept from './modules/cashiers/incomeoutputconcept'
import transaction from './modules/cashiers/transaction'
import bankaccount from './modules/cashiers/bankaccount'
import incomeoutput from './modules/cashiers/incomeoutput'


import order from './modules/sales/order'
import orderdetail from './modules/sales/orderdetail'
import sale from './modules/sales/sale'
import saledetail from './modules/sales/saledetail'
import creditnote from './modules/sales/creditnote'
import creditnotedetail from './modules/sales/creditnotedetail'
import sunatsummary from './modules/sales/sunatsummary'
import sunatvoided from './modules/sales/sunatvoided'
import invoicetype from './modules/sales/invoicetype'
import invoicetypeserie from './modules/sales/invoicetypeserie'
import saleinvoice from './modules/sales/saleinvoice'

const store = new Vuex.Store({
    state: {
        //
        //loading: boolean
    },
    mutations: {
        //
    },
    actions: {

    },
    modules: {
        app,
        session,
        user,
        role,
        tab,
        application,
        report,
        company,
        branch,
        dashboard,
        backup,

        customer,
        address,
        documenttype,
        group,

        booking,
        bookingproduct,


        category,
        manufacturer,
        measureunit,
        service,
        product,
        producttype,
        productsearch,
        schedule,
        productschedule,
        kit,
        pack,
        
        supplier,
        purchase,
        purchasedetail,

        country,
        department,
        province,
        district,
        currency,
        exchangerate,
        tax,
        configuration,
        generaltype,
        generaltypevalue,
        notification,
        printer,
        imagetype,

        warehouse,
        stock,
        stockavailable,
        stockmvt,
        stockmvtreason,

        cashier,
        cashierhistory,
        paymentmethod,
        paymentgateway,
        incomeoutputconcept,
        transaction,
        invoicetype,
        invoicetypeserie,
        dailyclosure,
        bankaccount,
        incomeoutput,

        order,
        orderdetail,
        sale,
        saledetail,
        creditnote,
        creditnotedetail,
        sunatsummary,
        sunatvoided,
        saleinvoice,
    }
});

export default store;