import Entity from '../entity'
import SaleDetail from './saledetail';
import Customer from '../customers/customer';
import Currency from '../preferences/currency';
import Address from '../customers/address';
import Transaction from '../cashiers/transaction';

export default class Sale extends Entity<number>{
    reference: string;
    customer_id: number;
    branch_id: number;
    delivery_address_id: number;
    currency_id: number;
    currency_sign: string;
    seller_id: number;
    invoice_type_id: number;
    payment_type_id: number;
    current_state_id: number;
    order_id: number;
    booking_id: number;
    tax_id: number;
    tax_rate: number;
    credit_days: number;
    exchange_rate: number;
    including_taxes: boolean;
    discount_type: string;
    subtotal: number;
    subtotal_wt: number;
    tax_igv: number;
    tax_isc: number;
    tax_others: number;
    taxes: number;
    discounts: number;
    total: number;
    total_wt: number;
    total_paid: number;
    total_return: number;
    balance: number;
    sale_date: string;
    expiration_date: string;
    invoice_serie: string;
    invoice_number: string;
    invoice_date: string;
    notes: string;
    valid: boolean;
    invoiced: boolean;
    delivered: boolean;
    returned: boolean;
    creditnote: number;
    order_nro: string;
    booking_nro: string;
    cancel_reason: string;
    details: Array<SaleDetail>;
    payments: Array<Transaction>;

    current_state: any;
    invoice_type_name: string;
    invoice_type: any;
    customer: Customer;
    currency: Currency;
    delivery_address: Address;
    address_full: string;
    customer_code: string;
    customer_document: string;
    customer_name: string;
    customer_email: string;
    
}
