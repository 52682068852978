import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ProductSchedule from '../../entities/catalogs/productschedule'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ProductScheduleState extends ListState<ProductSchedule>{
    editModel:ProductSchedule;
    product: number;
    reportList: Array<any>;
}
class ProductScheduleModule extends ListModule<ProductScheduleState,any,ProductSchedule>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:10,
        list: new Array<ProductSchedule>(),
        loading:false,
        editModel:new ProductSchedule(),
        activeList: new Array<ProductSchedule>(),
        product: 0,
        reportList: new Array<any>(),
        path: 'products-schedules',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async save(context: ActionContext<ProductScheduleState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/products-schedules', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const productscheduleModule=new ProductScheduleModule();
export default productscheduleModule;