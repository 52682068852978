import Entity from '../entity'
import Address from '../customers/address';

export default class Branch extends Entity<number>{
    address_id: number;
    company_id: number;
    warehouse_id: number;
    cashier_id: number;
    printer_id: number;
    name: string;
    note: string;
    printer_url: string;
    active: boolean;

    address: Address;
}


