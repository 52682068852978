import Entity from '../entity'

export default class Pack extends Entity<number>{
    product_id: number;
    item_id: number;
    item_type_id: number;
    currency_id: number;
    code: string;
    item_name: string;
    item_price: number;
    item_unity_id: number;
    item_unit_price: number;
    quantity: number;

    key: string;
    reference: string;
    unity_sym: string;
    currency_sign: string;
}


