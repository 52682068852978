import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Purchase from '../../entities/purchases/purchase'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';
import PurchaseDetail from '@/store/entities/purchases/purchasedetail';
import PurchaseHistory from '@/store/entities/purchases/history';

interface PurchaseState extends ListState<Purchase> {
    editModel: Purchase;
    supplier: number;
    hisLoading: boolean;
    history: Array<PurchaseHistory>;
    reportList: Array<any>;
    filename: '';
}
class PurchaseModule extends ListModule<PurchaseState, any, Purchase>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Purchase>(),
        loading: false,
        hisLoading: false,
        editModel: new Purchase(),
        supplier: null,
        activeList: new Array<Purchase>(),
        history: new Array<PurchaseHistory>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'purchases'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PurchaseState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Purchase>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }

            context.state.list.forEach(item => {
                let state = Util.abp.list.getItem(item.current_state_id);
                item['state_name'] = state.name;
                item['state_color'] = state.extra;
                if (item.received) {
                    item['state_name'] += ' / Ingresado';
                } else if (item.partial_received) {
                    item['state_name'] += ' / Ingreso Parcial';
                    item['state_color'] = 'green';
                }
            });

            return context.state.list;
        },
        async get(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id);
            context.state.editModel = reponse.data.result as Purchase;
            context.state.loading = false;
            return context.state.editModel;
        },
        async getLastNumber(context: ActionContext<PurchaseState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/purchases/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async update(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async allowEdit(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/purchases/allow-edit', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },        
        async getHistory(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/purchases/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async reportSunatFormat8(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/sunat-format8', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportPurchasesQuery(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/purchases-query', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PurchaseState, model: Purchase) {
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            state.supplier = null;

            state.editModel = Object.assign({});
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.due_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_PURCHASE_DOCUMENT');
            state.editModel.invoice_type_name = 'Tipo de Comprobante';
            state.editModel.including_taxes = true;
            state.editModel.exchange_rate = 1;
            state.editModel.subtotal = 0.00;
            state.editModel.subtotal_wt = 0.00;
            state.editModel.taxes = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00;
            state.editModel.invoice_number = '';
            state.editModel.supplier_id = null;
            state.editModel.supplier_name = '';
            state.editModel.supplier_document = '';
            state.editModel.supplier_address = '';
            state.editModel.credit_days = 0;
            state.editModel.number = 0;
            state.editModel.details = new Array<PurchaseDetail>();
        },
        edit(state: PurchaseState, model: Purchase) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            if (model.invoice_type != undefined)
                state.editModel.invoice_type_name = model.invoice_type.name;

        },
        setCurrency(state: PurchaseState, currency: any) {
            state.editModel.currency = currency;
            state.editModel.currency_id = currency.id;
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
        },
        setSupplier(state: PurchaseState, data: any) {
            state.editModel['supplier'] = data;
            state.editModel.supplier_id = data.id;
            state.editModel.supplier_document = data.document;
            state.editModel.supplier_name = data.name;
            state.supplier = data.id;
        },
        updateAmount(state: PurchaseState, details: Array<PurchaseDetail>) {
            state.editModel.subtotal = 0;
            state.editModel.subtotal_wt = 0;
            state.editModel.taxes = 0;

            details.forEach((item, index) => {
                state.editModel.subtotal += item.total;
                state.editModel.subtotal_wt += item.total_wt;
            });

            state.editModel.total_wt = state.editModel.subtotal_wt;
            state.editModel.total = state.editModel.subtotal;
            state.editModel.taxes = state.editModel.total_wt - state.editModel.total;
            state.editModel.balance = state.editModel.total_wt - state.editModel.total_paid;
        },
        updatePayments(state: PurchaseState, total_paid: any) {
            state.editModel.total_paid = parseFloat(total_paid);
            state.editModel.balance = parseFloat((state.editModel.total_wt - state.editModel.total_paid).toFixed(2));
        },
        setInvoiceType(state: PurchaseState, invoice_type: any) {
            state.editModel.invoice_type_id = invoice_type.id;
            state.editModel.invoice_type_name = invoice_type.name;
        },
        setReportList(state: PurchaseState, list: Array<any>) {
            state.reportList = list;
            state.totalCount = list.length;
        },
    });
}
const purchaseModule = new PurchaseModule();
export default purchaseModule;