import Entity from '../entity'
import MeasureUnit from '../catalogs/measureunit';
import Product from '../catalogs/product';

export default class CreditNoteDetail extends Entity<number>{
    credit_note_id: number;
    product_id: number;
    warehouse_id: number;
    unity_id: number;
    sale_detail_id: number;
    code: string;
    product_name: string;
    quantity: number;
    price: number;
    price_wt: number;
    taxes: number;
    total: number;
    total_wt: number;
    discounts: number;
    stock: number;
    unity: MeasureUnit;
    product: Product;
    service: boolean;
    gift: boolean;
}


