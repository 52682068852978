import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import BookingProduct from '../../entities/bookings/bookingproduct'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import Booking from '@/store/entities/bookings/booking';

interface BookingProductState extends ListState<BookingProduct>{
    editModel:BookingProduct;    
    booking: number;      
    features: any;      
    lastSeq: number;      
}
class BookingProductModule extends ListModule<BookingProductState,any,BookingProduct>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<BookingProduct>(),
        loading:false,
        editModel: new BookingProduct(),
        activeList: new Array<BookingProduct>(),
        features: {},
        booking: null,
        lastSeq: 0,
        path: 'booking-products'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<BookingProductState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        init(state: BookingProductState, model: Booking) {
            state.editModel = Object.assign({});
            state.booking = 0;
            state.list = [];
            if (model != undefined) {
                state.booking = model.id;
                if (model.products != null)
                    state.list = model.products;
            }
            state.list.forEach((a, k) => {
                a.seq = k;
            });
        },
        add(state: BookingProductState, model: any) {
            let prod = new BookingProduct();
            prod.currency_id = model.currency_id;
            prod.exchange_rate = model.exchange_rate;
            prod.product = model.product;
            prod.product_id = model.product.id;
            prod.product_name = model.product.name;
            prod.schedule_id = Util.abp.setting.get('DEFAULT_SCHEDULE');
            prod.schedule = null;
            prod.duration = 1;
            prod.discount_type = 'percent';
            prod.discounts = 0;
            prod.code = model.product.code;
            prod.price = model.product.price;
            prod.price_wt = model.product.price_wt;
            prod.product_price = model.product.price_wt;   
            prod.seq = state.list.length;   

            let pr = model.product.prices.filter(a => { return a.schedule_id == prod.schedule_id })[0];
            if (pr != null) {
                prod.price = pr.price;
                prod.price_wt = pr.price_wt;
                prod.product_price = pr.price_wt;
            }

            prod.total = prod.price;
            prod.total_wt = prod.price_wt;
            prod.taxes = prod.total_wt - prod.total; 

            state.list.push(prod);
        },
        new(state: BookingProductState, model: Booking) {
            state.editModel = Object.assign({});
            state.editModel.currency_id = model.currency_id;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.product_id = 1;
            state.editModel.schedule_id = Util.abp.setting.get('DEFAULT_SCHEDULE');
            state.editModel.schedule = null;
            state.editModel.duration = 1;            
            state.editModel.discount_type = 'percent';            
            state.editModel.discounts = 0;            
            state.editModel.price = 0;            
            state.editModel.price_wt = 0;
            state.editModel.product_price = 0;
        },
        edit(state: BookingProductState, model: BookingProduct) {
            state.editModel = model;
        },
        save(state: BookingProductState, product: BookingProduct) {
            if (product.seq >= 0) {
                let index = product.seq;
                state.list[index].duration = product.duration;
                state.list[index].price = product.price;
                state.list[index].price_wt = product.price_wt;
                state.list[index].product_price = product.product_price;
                state.list[index].discount_type = product.discount_type;
                state.list[index].discounts = product.discounts;
                state.list[index].taxes = product.taxes;
                state.list[index].total = product.total;
                state.list[index].total_wt = product.total_wt;
                state.list[index].schedule_id = product.schedule_id;
                state.list[index].schedule = product.schedule;
            } else {
                state.list.push(product);
            }            
        },
        delete(state: BookingProductState, index: number) {
            state.list.splice(index, 1);
            state.list.forEach((a, k) => {
                a.seq = k;
            });
        },
    });
}
const bookingproductModule=new BookingProductModule();
export default bookingproductModule;