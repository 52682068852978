import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import SaleDetail from '../../entities/sales/saledetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import Sale from '@/store/entities/sales/sale';

interface SaleDetailState extends ListState<SaleDetail>{
    editModel:SaleDetail;    
    sale: number;
    tax: any;
}
class SaleDetailModule extends ListModule<SaleDetailState,any,SaleDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<SaleDetail>(),
        loading:false,
        editModel: new SaleDetail(),
        activeList: new Array<SaleDetail>(),
        tax: null,
        path: 'sales'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<SaleDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: SaleDetailState, model: SaleDetail) {
            state.editModel = model;
        },
        init(state: SaleDetailState, model: Sale) {
            state.editModel = Object.assign({});
            state.tax = null;

            if (model != undefined) {
                state.sale = model.id;
                state.list = model.details;
                
                state.list.forEach((item, index) => {
                    if (item.credit_node_detail_id != null && item.credit_node_detail_id > 0 && item.quantity_returned >= item.quantity)
                        item['class'] = 'removed_item';

                    if (item.batches != null) {
                        item.batches.forEach(ba => {
                            ba.warehouse_id = item.warehouse_id;
                        });
                    }

                });   
            }
        },
        add(state: SaleDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id;
            });

            if (exist.length > 0) {
                exist[0].quantity += product.quantity;
                exist[0].total = exist[0].quantity * exist[0].price;
                exist[0].total_wt = exist[0].quantity * exist[0].price_wt
                exist[0].taxes = parseFloat((exist[0].total_wt - exist[0].total).toFixed(6));
            } else {
                let detail = new SaleDetail();
                detail.sale_id = state.editModel.id;
                detail.product = product;
                detail.product_id = product.id;
                detail.warehouse_id = (product['warehouse_id'] != undefined ? product['warehouse_id'] : Util.abp.session.warehouseId);
                detail.unity_id = product.unity_id;
                detail.unity_sym = product.unity_symbol;
                detail.tax = (state.tax != null ? state.tax : product.tax);
                detail.tax_id = (state.tax != null ? state.tax.id : product.tax_id);
                detail.tax_rate = (state.tax != null ? state.tax.rate : product.tax_rate);
                detail.exchange_rate = product['exchange_rate'] != undefined ? product['exchange_rate'] : 1;
                detail.code = product.code;
                detail.product_name = product.name;
                detail.stock_physical = product.stock_physical;
                detail.stock_reserved = product.stock_reserved;
                detail.stock = product.stock;
                detail.total_returned = 0;
                detail.quantity = product.quantity;                
                detail.price_wt = parseFloat(String(product.price_wt));
                detail.price = Util.removeTaxes(detail.price_wt, detail.tax_rate);
                detail.discount_type = (product['discount_type'] != null ? product['discount_type'] : 'percent');
                detail.discount_manual = false;
                detail.discounts = (product['discount'] != null ? product['discount'] : 0);
                detail.total = parseFloat((detail.quantity * detail.price).toFixed(6));
                detail.total_wt = parseFloat((detail.quantity * detail.price_wt).toFixed(6));
                detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(3));
                detail.product_price = product['product_price'];
                detail.product_cost = product.product_cost;
                detail.service = product['service'];
                detail.gift = product['gift'];
                if (detail.gift) {
                    detail.discounts = 0;
                }
                Util.updateTotalDetails(detail);             

                state.list.push(detail);
            }
        },
        updateList(state: SaleDetailState, data: any) {
            state.list[data.index].warehouse_id = data.detail.warehouse_id;
            state.list[data.index].tax_id = data.detail.tax_id;
            state.list[data.index].tax_rate = data.detail.tax_rate;
            state.list[data.index].quantity = data.detail.quantity;
            state.list[data.index].price = data.detail.price;
            state.list[data.index].price_wt = data.detail.price_wt;
            state.list[data.index].taxes = data.detail.taxes;
            state.list[data.index].total = data.detail.total;
            state.list[data.index].total_wt = data.detail.total_wt;
            state.list[data.index].discounts = data.detail.discounts;
            state.list[data.index].discount_type = data.detail.discount_type;
            state.list[data.index].discount_manual = data.detail.discount_manual;            
            state.list[data.index].gift = data.detail.gift;
        },
        delete(state: SaleDetailState, index: number) {
            state.list.splice(index, 1);
        },
        setPriceRule(state: SaleDetailState, data: any) {
            state.list.forEach(item => {
                if (item.product_id == data.product_id) {
                    item.product_price = data.product_price;
                    item.price_wt = data.price_wt;
                    item.price = data.price;
                    Util.updateTotalDetails(item);
                }
            });
        },
        setTax(state: SaleDetailState, tax: any) {
            state.tax = tax;
            state.list.forEach(item => {
                item.tax_id = tax.id;
                item.tax_rate = tax.rate;
                item.tax = tax;
                Util.updateTotalDetails(item);
            });
        },
        updateStocks(state: SaleDetailState, products: any) {
            state.list.forEach(item => {
                let exist = products.filter((product: any) => {
                    return item.product_id === product.id;
                });

                if (exist.length > 0) {
                    item.stock = exist[0].stock;
                }
            });
        },
    });
}
const saledetailModule=new SaleDetailModule();
export default saledetailModule;