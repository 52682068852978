import Entity from '../entity'

export default class ProductType extends Entity<number>{
    available_id: number;
    code: string;
    value: number;
    name: string;
    is_pack: boolean;
    has_stock: boolean;
    for_pack: boolean;
    active: boolean;
}


