import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Customer from '../../entities/customers/customer'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import axios from 'axios'
import Address from '../../entities/customers/address'

interface CustomerState extends ListState<Customer>{
    editModel:Customer;
    activeList: Array<Customer>;
    reportList: Array<any>;
    loadingCL: boolean;
    filename: string;
}
class CustomerModule extends ListModule<CustomerState,any,Customer>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Customer>(),
        loading:false,
        loadingCL: false,
        editModel: new Customer(),
        activeList: new Array<Customer>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'customers'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            if (payload.data.address != null && payload.data.address.ubigeo != null) {
                payload.data.address.department_id = payload.data.address.ubigeo.department;
                payload.data.address.province_id = payload.data.address.ubigeo.province;
                payload.data.address.district_id = payload.data.address.ubigeo.district;
            }

            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async createFromSunat(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            
            let response = await Ajax.post('/api/' + (context.state as any).path + '/sunat', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async update(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            if (payload.data.address != null && payload.data.address.ubigeo != null) {
                payload.data.address.department_id = payload.data.address.ubigeo.department;
                payload.data.address.province_id = payload.data.address.ubigeo.province;
                payload.data.address.district_id = payload.data.address.ubigeo.district;
            }

            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            let model = response.data.result;
            if (context.state.activeList != undefined) {
                context.state.activeList.forEach((item, k) => {
                    if (item.id == model.id)
                        context.state.activeList[k] = Util.extend(context.state.activeList[k], model);
                });
            }

            context.state.list.forEach((item, k) => {
                if (item.id == model.id)
                    context.state.list[k] = Util.extend(context.state.list[k], model);
            });

            return model;
        },
        async get(context: ActionContext<CustomerState, any>, payload: any) {
            if (payload.id == undefined) return {};
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            let item = reponse.data.result as Customer;
            context.state.list.push(item);
            context.state.loading = false;
            return item;
        },
        async getDataByDocument(context: ActionContext<CustomerState, any>, payload: any) {
            let result = null;
            let url = '/api/customers/get-data-by-' + payload.data.type.toLowerCase();
            await Ajax.get(url, { params: payload.data }).then(response => {
                result = response;
            }).catch(e => {
                result = null;
            });

            if (result && result.data.success)
                return result.data.result;
            else
                return false;
        },
        async getLastCode(context: ActionContext<CustomerState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/customers/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
        async updateBatch(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/customers/batch', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async importData(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/customers/import',
                payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = response.data.result;
            return true;
        },
        async reportCustomerQuery(context: ActionContext<CustomerState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/customer-query', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            if (payload.data.type == 'r')
                context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: CustomerState, model: Customer) {
            state.editModel = model;

	    if (state.editModel.address == null) {
                let addr = new Address();
                addr.id = 0;
                addr.country_id = Util.abp.setting.get('DEFAULT_COUNTRY');
                addr.address1 = '';
                addr.ubigeo = {};
                state.editModel.address = addr;
            }
            if (Util.abp.setting.get('ENABLE_CUSTOMER_LOGIN') == 1 && model['account'] != null) {
                state.editModel.username = model['account'].username;
            } 
        },
        new(state: CustomerState, model: Customer) {
            state.editModel = Object.assign({});
            state.editModel.address = null;
            state.editModel.code = Util.abp.setting.get('CUSTOMER_CODE_FORMAT');
            state.editModel.group_id = Util.abp.setting.get('DEFAULT_GROUP');
            state.editModel.document_type_id = Util.abp.setting.get('DEFAULT_DOCUMENT');
            state.editModel.person_type_id = Util.abp.setting.get('DEFAULT_PERSON_TYPE');
             
            let addr = new Address();
            addr.id = 0;
            addr.country_id = Util.abp.setting.get('DEFAULT_COUNTRY');
            addr.address1 = '';
            addr.ubigeo = {};
            state.editModel.address = addr;
        },
        setReportList(state: CustomerState, list: Array<any>) {
            state.reportList = list;
        },
    });
}
const customerModule=new CustomerModule();
export default customerModule;