declare global {
    interface System {
        import(request: string): Promise<any>
    }
    var System: System
}
import Vue from 'vue';
import appconst from './appconst'
import Cookies from 'js-cookie';
import { dbRouters, otherRouters } from '../router/router'

class Util{
    abp:any=window.abp;
    loadScript(url:string){
        var script=document.createElement('script');
        script.type="text/javascript";
        script.src=url;
        document.body.appendChild(script);
    }
    title(title: string) {
        //let appname = this.abp.localization.localize(this.abp.setting.get('COMPANY_NAME'),appconst.localization.defaultLocalizationSourceName);
        let appname = this.abp.setting.get('COMPANY_NAME') != undefined ? this.abp.setting.get('COMPANY_NAME') : appconst.setting.appName;
        let page = this.abp.localization.localize(title, appconst.localization.defaultLocalizationSourceName);
        window.document.title = appname + (page != undefined ? ' - ' + page : '');
    }
    inOf(arr:Array<any>, targetArr:any) {
        let res = true;
        arr.forEach(item => {
            if (targetArr.indexOf(item) < 0) {
                res = false;
            }
        });
        return res;
    }
    oneOf(ele:any, targetArr:Array<any>) {
        if (targetArr.indexOf(ele) >= 0) {
            return true;
        } else {
            return false;
        }
    }
    showThisRoute (itAccess:any, currentAccess:any) {
        if (typeof itAccess === 'object' && Array.isArray(itAccess)) {
            return this.oneOf(currentAccess, itAccess);
        } else {
            return itAccess === currentAccess;
        }
    }
    getRouterObjByName (routers:Array<any>, name?:string):any {
        if (!name || !routers || !routers.length) {
            return null;
        }
        // debugger;
        let routerObj = null;
        for (let item of routers) {
            if (item.name === name) {
                return item;
            }
            routerObj = this.getRouterObjByName(item.children, name);
            if (routerObj) {
                return routerObj;
            }
        }
        return null;
    }
    toDefaultPage(routers: Array<any>, name: string | undefined, route: any, next: any) {
        let len = routers.length;
        let i = 0;
        let notHandle = true;
        while (i < len) {
            if (routers[i].name === name && routers[i].children && routers[i].redirect === undefined) {
                route.replace({
                    name: routers[i].children[0].name
                });
                notHandle = false;
                next();
                break;
            }
            i++;
        }
        if (notHandle) {
            next();
        }
    }
    toRoute(vm: Vue, name: string | undefined, query?: any) {
        let routerObj: any = {};
        routerObj.name = name; 
        routerObj.query = query;
        vm.$router.push(routerObj);
    }
    handleTitle (vm:any, item:any) {
        if (typeof item.meta.title === 'object') {
            return vm.$t(item.title.i18n);
        } else {
            return item.meta.title;
        }
    }
    setCurrentPath(vm: Vue, name?: string) { 
        let title = '';
        let isOtherRouter = false;
        vm.$store.state.app.routers.forEach((item: any) => {
            if (item.children != null) {
                if (item.children.length === 1) {
                    if (item.children[0].name === name) {
                        title = this.handleTitle(vm, item);
                        if (item.name === 'otherRouter') {
                            isOtherRouter = true;
                        }
                    }
                } else {
                    item.children.forEach((child: any) => {
                        if (child.name === name) {
                            title = this.handleTitle(vm, child);
                            if (item.name === 'otherRouter') {
                                isOtherRouter = true;
                            }
                        }
                    });
                }
            }
        });
        let currentPathArr = [];
        if (name === 'home') {
            currentPathArr = [
                {
                    meta:{title: this.handleTitle(vm, this.getRouterObjByName(vm.$store.state.app.routers, 'home'))},
                    path: 'main/home',
                    name: 'home'
                }
            ];
        } else if (((name as string).indexOf('index') >= 0 || isOtherRouter) && name !== 'home') {
            currentPathArr = [
                {
                    meta:{title: this.handleTitle(vm, this.getRouterObjByName(vm.$store.state.app.routers, 'home'))},
                    path: 'main/home',
                    name: 'home'
                },
                {
                    meta:{title: title},
                    path: '',
                    name: name
                }
            ];
        } else {
            let currentPathObj = vm.$store.state.app.routers.filter((item: any) => {
                if (item.children != undefined) {
                    if (item.children.length <= 0) {
                        return item.name === name;
                    } else if (item.children.length == 1) {
                        return item.children[0].name === name || item.name === name;
                    } else {
                        let i = 0;
                        let childArr = item.children;
                        let len = childArr.length;
                        while (i < len) {
                            if (childArr[i].name === name) {
                                return true;
                            }
                            i++;
                        }
                        return false;
                    }
                } else
                    return item.name === name;
            })[0];
            if ((currentPathObj != undefined && currentPathObj.children && currentPathObj.children.length <= 1 && currentPathObj.name === 'home') || currentPathObj == undefined) {
                currentPathArr = [
                    {
                        meta: { title: 'Inicio'},
                        path: 'main/home',
                        name: 'home'
                    }
                ];
            } else if (currentPathObj.children&&currentPathObj.children.length <= 1 && currentPathObj.name !== 'home') {
                currentPathArr = [
                    {
                        meta: { title: 'Inicio'},
                        path: 'main/home',
                        name: 'home'
                    },
                    { 
                        meta:{title: currentPathObj.meta.title},
                        path: '',
                        name: name
                    }
                ];
            } else {
                let childObj = null;
                if (currentPathObj.children != undefined) {
                    childObj = currentPathObj.children.filter((child: any) => {
                        return child.name === name;
                    })[0];
                }
                currentPathArr = [
                    {
                        meta: { title: 'Inicio'},
                        path: 'main/home',
                        name: 'home'
                    },
                    {
                        meta:{title: currentPathObj.meta.title},
                        path: '',
                        name: ''
                    }                    
                ];

                if (childObj != null) {
                    currentPathArr.push({
                        meta: { title: childObj.meta.title },
                        path: currentPathObj.path + '/' + childObj.path,
                        name: name
                    });
                }
            }
        }
        vm.$store.commit('app/setCurrentPath', currentPathArr);
    
        return currentPathArr;
    }
    openNewPage(vm: Vue, name: string | undefined, argu?: any, query?: any) {
        if (vm.$store == undefined) return;
        let pageOpenedList = vm.$store.state.app.pageOpenedList;
        let openedPageLen = pageOpenedList.length;
        let i = 0;
        let tagHasOpened = false;

        if (query != undefined && query.app != undefined) {
            vm.$store.commit('app/setAppId', query.app);
            vm.$store.commit('app/updateMenulist');
        }

        while (i < openedPageLen) {
            if (name === pageOpenedList[i].name) { // La página ya está abierta
                vm.$store.commit('app/pageOpenedList', {
                    index: i,
                    argu: argu,
                    query: query
                });
                tagHasOpened = true;
                break;
            }
            i++;
        }
        if (!tagHasOpened) {
            let tag = vm.$store.state.app.tagsList.filter((item: any) => {
                if (item == undefined) return false;
                if (item.children == undefined) {
                    return name === item.name;                    
                } else if (item.children.length == 0) {
                    return name === item.name;
                } else {
                    return name === item.children[0].name;
                }
            });
            tag = tag[0];
            if (tag) {
                tag = tag.children ? tag.children[0] : tag;
                if (argu) {
                    tag.argu = argu;
                }
                if (query) {
                    tag.query = query;
                }
                vm.$store.commit('app/increateTag', tag);
            }
        }
        vm.$store.commit('app/setCurrentPageName', name);
    }
    fullscreenEvent (vm:Vue) {
        vm.$store.commit('app/initCachepage');
        // Filtro de menú de permisos relacionado
        vm.$store.commit('app/updateMenulist');
        // Pantalla completa relacionada
    }
    toggleFullScreen () {
        let doc = window.document;
        let docEl = doc.documentElement;

        //let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
        //let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
        let requestFullScreen = docEl.requestFullscreen;
        let cancelFullScreen = doc.exitFullscreen;

        //if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        if (!doc.fullscreenElement && !(doc as any).mozFullScreenElement) {
            requestFullScreen.call(docEl);
        }
        else {
            cancelFullScreen.call(doc);
        }
    }
    extend(...args:any[]) {
        let options, name, src, srcType, copy, copyType, copyIsArray, clone,
        target = args[0] || {},
        i = 1,
        length = args.length,
        deep = false;
        if ( typeof target === 'boolean') {
            deep = target;
            target = args[i] || {};
            i++;
        }
        if ( typeof target !== 'object' && typeof target !== 'function') {
            target = {};
        }
        if ( i === length) {
            target = this;
            i--;
        }
        for ( ; i < length; i++ ) {
            if ( (options = args[i]) !== null ) {
                for ( name in options ) {
                    src = target[name];
                    copy = options[name];
                    if ( target === copy ) {
                        continue;
                    }
                    srcType = Array.isArray(src) ? 'array': typeof src;
                    if ( deep && copy && ((copyIsArray = Array.isArray(copy)) || typeof copy === 'object')) {
                        if ( copyIsArray ) {
                            copyIsArray = false;
                            clone = src && srcType === 'array' ? src : [];
                        } else {
                            clone = src && srcType === 'object' ? src: {};
                        }
                        target[name] = this.extend(deep, clone, copy);
                    } else if ( copy !== undefined ) {
                        target[name] = copy;
                    }
                }
            }
        }
        return target;
    }
    buildMenuRoutes(vm: Vue, menuListDb: Array<Router>) {
        let menuListResult: Array<Router> = [];
        let newRoutes: Array<Router> = [];
        if (menuListDb != null) {
            menuListDb.forEach((item, index) => {
                if (item.children && item.children.length > 0) {
                    item.component = () => import('../views/main.vue');
                    let childrenNew: Array<Router> = [];
                    childrenNew = item.children.filter(child => {
                        let routstmp = dbRouters;

                        let name = child.name.toLowerCase().replace('-', '');
                        if (name != undefined)
                            child.component = dbRouters[name];

                        return child;
                    });
                    item.children = childrenNew;
                } else {
                    if (dbRouters[item.name.toLowerCase()] != undefined) {
                        item.component = dbRouters[item.name.toLowerCase()];
                    }
                }
                menuListResult.push(item);

                let filList = otherRouters.children.filter(child => {
                    return (child.name == item.name);
                });
                if (filList.length == 0) newRoutes.push(item);
            });
        }
        vm.$router.addRoutes(newRoutes);
        return menuListResult;
    }
    getEntityValue(item, key) {
        if (key == null) return null;
        var i_split = key.split(".");
        var itemValue = item;

        for (var c = 0; c < i_split.length; c++)
            if (itemValue != null) itemValue = itemValue[i_split[c]];

        return itemValue;
    }
    findByParam(list, param, value) {
        if (list == undefined) return [];
        let filterList = list.filter((child: any) => {
            return child != null && String(child[param]).toUpperCase() === String(value).toUpperCase();
        });
        return filterList;
    }
    getByParam(vm: Vue, entity, param, value) {
        if (vm.$store.state[entity].activeList == undefined) return {};

        return vm.$store.state[entity].activeList.filter((child: any) => {
            return child != null && child[param] === value;
        })[0];
    }
    getEntity(vm: Vue, entity, id) {
        if (vm.$store.state[entity].activeList == undefined) return {};
        let item = vm.$store.state[entity].activeList.filter((child: any) => {
            return child.id === id; 
        })[0]; 
        return item;
    }
    getList(vm: Vue, entity) {
        return vm.$store.state[entity].activeList;
    }
    getEntityField(vm: Vue, entity, id, field) {
        let obj = this.getEntity(vm, entity, id);
        if (obj != undefined) return obj[field];
        return null;
    } 
    getEntityByParam(vm: Vue, entity, param, value) {
        if (vm.$store.state[entity].activeList == undefined) return {};
        let item = vm.$store.state[entity].activeList.filter((child: any) => {
            return child[param] == value;
        })[0];
        return item;
    } 
    getTypeValueByCode(vm: Vue, type, code) {
        return this.abp.list.get(type, code);
    }
    getTypeValueById(id) {
        return this.abp.list.getItem(id);
    }
    getUnits(vm: Vue, id) {
        let items = [];
        if (vm.$store.state.measureunit.activeList == undefined) return [];
        let unity = this.getEntity(vm, 'measureunit', id);

        items.push(unity.id);
        let parent_id = unity.parent_id;
        while (parent_id > 0) {
            let parent = this.getEntity(vm, 'measureunit', parent_id);
            items.push(parent_id);
            parent_id = parent.parent_id;
        }

        //let items = vm.$store.state.measureunit.activeList.filter((child: any) => {
        //    return child.parent_id == id || child.id == id;
        //});
        return items;
    }
    getTotalPaid(vm: Vue, operation) {
        let paid = 0;
        let mt = (operation == 'purchase' || operation == 'payableaccount' ? 'E' : 'I');
        vm.$store.state.transaction.list.forEach(item => {
            let state = this.abp.list.getItem(item.state_id);
            if (state.code == 'V' && !state.canceled) {
                let amt = parseFloat(item.amount);
                amt = this.convertAmountToCurrency(amt, item.currency_id, vm.$store.state[operation].editModel.currency_id);
                paid += (amt * (item.type == mt ? 1 : -1));
            }
        });

        return paid;
    }
    getTotalAmountPaid(vm: Vue, operation) {
        let paid = 0;

        vm.$store.state.transaction.list.forEach(item => {
            let amt = parseFloat(item.amount_paid);
            amt = this.convertAmountToCurrency(amt, item.currency_id, vm.$store.state[operation].editModel.currency_id);
            paid += amt;
        });

        return paid;
    }
    getTotalReturn(vm: Vue, operation) {
        let paid = 0;         
        vm.$store.state.transaction.list.forEach(item => {
            let amt = parseFloat(item.amount_return);
            amt = this.convertAmountToCurrency(amt, item.currency_id, vm.$store.state[operation].editModel.currency_id);
            paid += amt;
        });

        return paid;
    }
    prepEntity(vm: Vue, items, data) {
        let newData = {id: 0};
        if (data.id != undefined) newData.id = data.id;
        items.forEach(item => {
            if (item.prop != undefined && typeof data[item.prop] == "object") {
                var prop = item.prop;
                if (prop.indexOf('_id') < 0) prop += '_id';
                if (data[item.prop] != undefined) {
                    if (item.prop.indexOf('address_') >= 0) {
                        if (newData['address'] == undefined) newData['address'] = { id: data.address_id };

                        if (item.type == 'ubigeo' && item.returnObject) {
                            newData['address']['department_id'] = data[item.prop]['department'];
                            newData['address']['district_id'] = data[item.prop]['district'];
                            newData['address']['province_id'] = data[item.prop]['province'];
                        } else
                            newData['address'][item.prop.replace('address_', '')] = data[item.prop].id;
                    } else if (Array.isArray(data[item.prop])) {
                        newData[item.prop] = data[item.prop];
                    } else                       
                        newData[prop] = data[item.prop].id;
                }
            } else if (item.prop != undefined) {
                if (item.prop.indexOf('address_') >= 0) {
                    if (newData['address'] == undefined) newData['address'] = {id: data.address_id};
                    newData['address'][item.prop.replace('address_','')] = data[item.prop];
                } else
                    newData[item.prop] = data[item.prop];
            }
            if (item.prefixProp != undefined) {
                if (typeof data[item.prefixProp] == "object") {
                    var prop = item.prefixProp;
                    if (prop.indexOf('_id') < 0) prop += '_id';
                    newData[prop] = data[item.prefixProp].id;
                } else {
                    newData[item.prefixProp] = data[item.prefixProp];
                }
            }
        });
        return newData;
    }
    setAddressProps(items, data) {
        let addrItems = [];
        if (data.address != null) {
            items.forEach(item => {
                if (item.prop != undefined && item.prop.indexOf('address_') >= 0) {
                    let prop = item.prop.replace('address_', '');
                    if (data.address[prop] != undefined)
                        data[item.prop] = data.address[prop];
                }
            });
        }
        return data;
    }
    findGeneralType(list, code, valueCode) {
        let gType = this.findByParam(list, 'code', code)[0];
        let obj = null;
        if (gType != null) {
            obj = this.findByParam(gType.values, 'code', valueCode)[0];
        }    
        return obj;
    }
    findByText(list, code, valueCode) {
        let gType = this.findByParam(list, 'code', code)[0];
        let obj = null;
        if (gType != null) {
            obj = this.findByParam(gType.values, 'code', valueCode)[0];
        }
        return obj;
    }
    addTaxes(price, rate = null, decimals = 6) {
        if (rate == null) rate = this.abp.setting.get('TAX_RATE');
        if (rate > 0) return parseFloat((price * (rate / 100 + 1)).toFixed(decimals));
        else return parseFloat(price);
    }
    removeTaxes(price, rate = null, decimals = 6) {
        if (rate == null) rate = this.abp.setting.get('TAX_RATE');
        if (rate > 0) return parseFloat((price / (rate / 100 + 1)).toFixed(decimals));
        else return parseFloat(price);
    }
    applyDiscounts(item, type, discount, action = 'add') {
        let discountMode = this.abp.setting.get('DISCOUNT_MODE');
        if (discountMode == undefined) discountMode = 'T';

        item.discount_type = (type != null ? type : 'percent');
        item.discounts = (discount != null ? parseFloat(discount) : 0);

        if (discountMode == 'T') {
            let total_wt = item.total_wt;
            if (item.discount_type == 'percent') {
                total_wt = parseFloat((item.total_wt * (1 - (item.discounts / 100))).toFixed(6));
            } else
                total_wt = parseFloat((item.total_wt - item.discounts).toFixed(6));
            item.total = parseFloat((item.total * total_wt / item.total_wt).toFixed(6));
            item.total_wt = total_wt;
        } else if (discountMode == 'B') {
            if (action == 'update') {
                item.price_wt = item.product_price;
                item.price = this.removeTaxes(item.price_wt, item.tax_rate, 2);
            }
            let price_wt = item.price_wt;
            if (item.discount_type == 'percent') {
                price_wt = parseFloat((item.price_wt * (1 - (item.discounts / 100))).toFixed(6));
            } else
                price_wt = parseFloat((item.price_wt - item.discounts).toFixed(6));
            item.price = parseFloat((item.price * price_wt / item.price_wt).toFixed(6));
            item.price_wt = price_wt;
            item.total = item.quantity * item.price;
            item.total_wt = item.quantity * item.price_wt
        }
    }
    applyCostDiscounts(item, type, discount, action = 'add') {
        item.discount_type = (type != null ? type : 'percent');
        item.discounts = (discount != null ? parseFloat(discount) : 0);

        if (item.discounts > 0) {
            let total_wt = item.total_wt;
            if (item.discount_type == 'percent') {
                total_wt = parseFloat((item.total_wt * (1 - (item.discounts / 100))).toFixed(6));
            } else
                total_wt = parseFloat((item.total_wt - item.discounts).toFixed(6));
            item.total = parseFloat((item.total * total_wt / item.total_wt).toFixed(6));
            item.total_wt = total_wt;
        }
    }
    getDiscounts(amount, type, discount): number {
        type = (type != null ? type : 'percent');
        discount = (discount != null ? parseFloat(discount) : 0);
        let result = 0;

        if (discount > 0) {
            if (type == 'percent') {
                result = parseFloat((amount * discount / 100).toFixed(6));
            } else
                result = discount;
        }
        return result;
    }
    addPercentage(amount, rate): number {
        return amount * (1 + (rate / 100));
    }
    getPriceByProfit(cost, profit, decimals = 3): number {
        profit = parseFloat(profit);
        cost = parseFloat(cost);
        return parseFloat((profit > 0 ? cost * (profit / 100 + 1) : cost).toFixed(decimals));
    }
    updateTotal(detail, taxes = true) {
        detail.quantity = parseFloat(String(detail.quantity));
        detail.cost_wt = parseFloat(String(detail.cost_wt));
        detail.total_wt = detail.quantity * detail.cost_wt;
        
        if (taxes) {
            let rate = this.abp.setting.get('TAX_RATE');
            detail.cost = this.removeTaxes(detail.cost_wt, rate);
            detail.total = detail.quantity * detail.cost;
            detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(6));
        }
    }
    updateTotalDetails(detail: any) {
        let mode = this.abp.setting.get('DISCOUNT_ROUNDING', 'no');

        if (detail.tax != null && detail.tax != undefined) {
            detail.tax_rate = detail.tax.rate;
            if (detail.tax_id == null)
                detail.tax_id = detail.tax.id;
        }        

        if (String(detail.quantity) == '') detail.quantity = 1;
        detail.quantity = parseFloat(String(detail.quantity));
        detail.price = this.removeTaxes(detail.price_wt, detail.tax_rate);
        detail.total = detail.quantity * detail.price;
        detail.total_wt = (detail.quantity * detail.price_wt);

        if (detail.discounts > 0) {
            let discount = (detail.discount_type == 'percent' ? detail.total_wt * detail.discounts / 100 : detail.discounts);
            detail.total_wt -= discount;

            if (detail.discount_type == 'percent' && mode != 'no') {
                detail.total_wt = (mode == 'up' ? Math.ceil(detail.total_wt) : Math.floor(detail.total_wt));
            }

            detail.total = this.removeTaxes(detail.total_wt, detail.tax_rate);
        }

        if (detail.total < 0) detail.total = 0;
        if (detail.total_wt < 0) detail.total_wt = 0;

        detail.total = parseFloat(detail.total.toFixed(6));
        detail.total_wt = parseFloat(detail.total_wt.toFixed(6));
        detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(6));
    }
    updateTotalCostDetails(detail: any, prices = null) {
        let allowDiscount = this.abp.setting.get('ALLOW_PRODUCT_COST_DISCOUNT') == 1;
        if (detail.tax != null && detail.tax != undefined) {
            detail.tax_rate = detail.tax.rate;
            if (detail.tax_id == null)
                detail.tax_id = detail.tax.id;
        }

        if (String(detail.quantity) == '') detail.quantity = 1;
        detail.quantity = parseFloat(String(detail.quantity));
        detail.cost = this.removeTaxes(detail.cost_wt, detail.tax_rate);
        detail.total = detail.quantity * detail.cost;
        detail.total_wt = parseFloat((detail.quantity * detail.cost_wt).toFixed(6));  

        if (allowDiscount && detail.discounts != undefined && detail.discounts > 0) {
            let discount = (detail.discount_type == 'percent' ? detail.total_wt * parseFloat(String(detail.discounts)) / 100 : parseFloat(String(detail.discounts)));

            detail.total_wt -= discount;
            detail.total = this.removeTaxes(detail.total_wt, detail.tax_rate);
        }

        if (detail.total < 0) detail.total = 0;
        if (detail.total_wt < 0) detail.total_wt = 0;

        detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(6));
        if (detail.prices == null || detail.prices == undefined) detail.prices = [];

        if (prices != null) {
            prices.forEach((item, index) => {
                if (item.profit > 0 && detail.cost_wt > 0) {
                    let det_cost_wt = this.convertAmountToCurrency(detail.cost_wt, detail.currency_id, item.currency_id);

                    item.price = this.getPriceByProfit(det_cost_wt, item.profit);
                }
            });
            detail.prices = prices;
        }
    }
    setOrderDetails(detail: any, decimals = 2) {
        if (detail.tax != null) {
            detail.tax_rate = detail.tax.rate;
            if (detail.tax_id == null)
                detail.tax_id = detail.tax.id;
        }    

        detail.quantity = (detail.quantity < 1 ? 1 : parseFloat(String(detail.quantity)));
        detail.discounts = (detail.discounts != null ? detail.discounts : 0);

        detail.aditional_price = 0;
        detail.aditional_price_wt = 0;
        if (detail.remarks != null) {
            detail.remarks.forEach(remark => {
                if (remark.aditionals != null) {
                    remark.aditionals.forEach(adic => {
                        detail.aditional_price += parseFloat(adic['price']);
                        detail.aditional_price_wt += parseFloat(adic['price_wt']);
                    })
                }
            });
        }

        detail.price = this.removeTaxes(detail.price_wt, detail.tax_rate);
        detail.total = detail.quantity * detail.price;
        detail.total_wt = (detail.quantity * detail.price_wt);

        if (detail.discounts > 0) {
            let discount = (detail.discount_type == 'percent' ? detail.total_wt * detail.discounts / 100 : detail.discounts);
            detail.total_wt -= discount;
            detail.total = this.removeTaxes(detail.total_wt, detail.tax_rate);
        }

        detail.total = detail.total + detail.aditional_price;
        detail.total_wt = detail.total_wt + detail.aditional_price_wt;

        if (detail.total < 0) detail.total = 0;
        if (detail.total_wt < 0) detail.total_wt = 0;

        detail.total = parseFloat(detail.total.toFixed(6));
        detail.total_wt = parseFloat(detail.total_wt.toFixed(6));
        detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(6));
    }
    getListToExport(list, columns) {
        let dataList = { header: [], headerTitle: [], list: [] };

        columns.forEach(col => {
            if (col.value != undefined && col.value != 'action') {
                dataList.header.push(col.value);
                dataList.headerTitle.push(col.text);
            }
        });

        list.forEach(item => {
            let newItem = {};
            columns.forEach(col => {
                if (col.value != undefined && col.value != 'action') {
                    let value = this.getEntityValue(item, col.value);
                    if (col.generalType) value = this.abp.list.getName(value);

                    if (col.type == 'percent')
                        value += ' %';
                    else if (col.type == 'price') {
                        let sign = (item['currency_sign'] != undefined ? item['currency_sign'] :
                            (item['currency'] != undefined ? item['currency'].sign : this.abp.setting.get('DEFAULT_CURRENCY_SIGN')));
                        value = sign + ' ' + parseFloat(value).toFixed(2);
                    } else if (col.type == 'date') {
                        value = (value != null ? value.substr(0, 10) : '');
                    }
                    newItem[col.value] = value;
                }
            });

            dataList.list.push(newItem);
        });
        return dataList;
        
    }
    convertToCurrency(vm: Vue, model, product) { 
        if (model.currency_id != undefined && model.currency_id != product.currency_id) {
            if (model.exchange_rate == 1) {
                let currency = this.findByParam(vm.$store.state.currency.activeList, 'id', product.currency_id)[0];
                product.price = parseFloat((product.price * currency.conversion_rate).toFixed(6));
                product.price_wt = parseFloat((product.price_wt * currency.conversion_rate).toFixed(6));
            } else {
                product.price = parseFloat((product.price / model.exchange_rate).toFixed(6));
                product.price_wt = parseFloat((product.price_wt / model.exchange_rate).toFixed(6));
            }
        }
    }
    convertCostToCurrency(vm: Vue, model, product) {
        if (model.currency_id != undefined && model.currency_id != product.currency_id) {
            if (model.exchange_rate == 1) {
                let currency = this.findByParam(vm.$store.state.currency.activeList, 'id', product.currency_id)[0];
                product.cost = parseFloat((product.cost * currency.conversion_rate).toFixed(6));
                product.cost_wt = parseFloat((product.cost_wt * currency.conversion_rate).toFixed(6));
                product.cost_wt = parseFloat(product.cost_wt.toFixed(3));
            } else {
                product.cost = parseFloat((product.cost / model.exchange_rate).toFixed(6));
                product.cost_wt = parseFloat((product.cost_wt / model.exchange_rate).toFixed(6));
            }
        }
    }
    convertAmountToCurrency(amount, currency_from, currency_to) {
        let defCurr = this.abp.setting.get('DEFAULT_CURRENCY');
        if (currency_from == undefined) currency_from = defCurr;
        let convert_amount: number = parseFloat(amount);
        if (convert_amount == undefined) return 0;
        if (currency_from != currency_to) {
            let rate = this.abp.setting.get('EXCHANGE_RATE');
            if (currency_from == defCurr) {
                convert_amount = convert_amount / rate;
            } else {
                convert_amount = convert_amount * rate;
            }
        }
        return parseFloat(convert_amount.toFixed(6));
    }
    buildProductDetail(vm, product, exchange_rate: any = 1) {
        let newDetail = this.extend(true, {}, product);

        let man = '';
        if (product.manufacturer != null) {
            man = product.manufacturer.name;
        } else if (product.manufacturer_id != null) {
            let manO = this.getEntity(vm, 'manufacturer', product.manufacturer_id);
            if (manO != undefined) {
                man = manO.name;
            }
        }          

        newDetail.exchange_rate = exchange_rate;
        newDetail.quantity = 1;
        newDetail.product_price = parseFloat(String(product.price_wt));
        newDetail.product_cost = parseFloat(String(product.cost));
        newDetail.manufacturer_name = man;
        newDetail.category_name = this.getEntityField(vm, 'category', product.category_id, 'name');
        newDetail.category_budget = this.getEntityField(vm, 'category', product.category_id, 'budget');
        newDetail.service = (product.service == 1);
        newDetail.location = null;
        newDetail.cost = parseFloat(newDetail.cost.toFixed(3));
        newDetail.cost_wt = parseFloat(newDetail.cost.toFixed(3));
        newDetail.total_wt = newDetail.quantity * newDetail.cost_wt;
        newDetail.stock_reserved = newDetail.stock_physical - newDetail.stock;

        return newDetail;
    }
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    today(vm: any, format?: number) {
        if (format == undefined) format = 1;
        if (format == 2)
            return vm.$moment().format('DD/MM/YYYY')
        else
            return vm.$moment().format('YYYY-MM-DD')
    }
    now(vm: any) {
        return vm.$moment().format('YYYY-MM-DD HH:mm:ss')
    }
    formatDate(vm: any, date) {
        return vm.$moment(date).format('YYYY-MM-DD HH:mm:ss')
    }
    firstDayOfMonth(vm: any, format?: number) {
        if (format == undefined) format = 1;
        if (format == 2)
            return '01' + vm.$moment().format('/MM/YYYY');
        else
            return vm.$moment().format('YYYY-MM-') + '01';        
    }
    rangeDates(vm: any, format?: number): Array<any> {
        if (format == undefined) format = 1; 
        return [this.firstDayOfMonth(vm, format), this.today(vm, format)];
    }
    isValidDate(date) {
        return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
    }
    lastDateOfMonth() {
        var today = new Date();
        return new Date(today.getFullYear(), today.getMonth() + 1, 0);
    }
    getInitials(string, first = false) {
        if (string == undefined) return '';
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
        
        if (!first && names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }
    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    zeroPadded(num) {
        // 4 --> 04
        return num < 10 ? `0${num}` : num;
    }
    formatMintues(minutes: number) {
        let seconds = minutes * 60;
        let hour = Math.floor(seconds / 3600);
        let minute = Math.floor((seconds / 60) % 60);
        let second = parseInt(String(seconds % 60));

        return `${this.zeroPadded(hour)}:${this.zeroPadded(minute)}:${this.zeroPadded(second)}`;
    }
    copyToClipboard(text) {
        if (typeof ((navigator as any).clipboard) == 'undefined') {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            textArea.style.position = "fixed";  //avoid scrolling to bottom
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
            } catch (err) {
                console.log(err);
            }

            document.body.removeChild(textArea)
            return;
        } else {
            (navigator as any).clipboard.writeText(text);
        }
    }
    replaceEmailContent(content: string, data) {
        let newContent = content;
        let list = this.abp.list.getList('TEMPLATE_FIELDS');
        list.forEach(item => {
            if (item.code == 'link') {
                newContent = newContent.replace('{{link}}', this.abp.setting.get('APP_LINK'));
            } else if (data[item.code] != undefined) {
                newContent = (newContent as any).replaceAll('{{' + item.code +'}}', data[item.code]);
            }
        });
        return newContent;
    }
    formatNumber(amount, decimals = 0) {
        if (amount == null) return '';
        amount = parseFloat(amount);
        if (decimals == 0) {
            amount = Math.round(amount);
            return new Intl.NumberFormat().format(amount);
        } else
            return amount.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    addHours(start, hours) {
        let parts = start.split(':');
        return (parseInt(parts[0]) + parseInt(hours)) + ':' + parts[1];
    }
    containArray(arr1, arr2) {
        const set1 = new Set(arr1);
        return arr2.some(item => set1.has(item));
    }
    setMessages(list) {
        let messages = [];
        list.forEach(mess => {
            let author = (mess.author_id == this.abp.session.userId ? `me` : mess.author_code);
            let data = {};
            if (mess.text != null) data['text'] = mess.text;
            if (mess.code != null) data['emoji'] = mess.code;
            if (mess.filename != null) {
                data['file'] = {
                    name: mess.filename,
                    url: mess.fileurl,
                };
            }
            messages.push({ type: mess.type, author: author, data: data });
        });
        return messages;
    }
    setFavicon() {
        let key = process.env.VUE_APP_NAME + '_favicon';
        if (this.abp.setting.get('COMPANY_FAVICON') != null && Cookies.get(key) == undefined) {
            Cookies.set(key, this.abp.setting.get('COMPANY_FAVICON'));
        }

        if (Cookies.get(key) != undefined) {
            let url = Cookies.get(key);
            let sizes = ['57', '60', '72', '76', '114', '120', '144', '152', '180', '192', '32', '96', '16'];
            sizes.forEach(s => {
                const favicon = document.getElementById("favicon" + s);
                let text = '';
                let name = 'apple-icon-';
                if (s == '192') name = 'android-icon-';
                else if (s == '32' || s == '96' || s == '16') name = 'favicon-';
                text = name + s + 'x' + s + '.png';
                (favicon as any).href = url + text;
            });
        }
    }
}
const util=new Util();
export default util;